<!-- eslint-disable vue/no-v-html -->
<template>
	<custom-modal
		:show="showModal"
		:title="translate('configure_autoship_title')"
		ref-name="autoshipInfoModal"
		:confirm-text="translate('configure_autoship_confirm')"
		:cancel-text="translate('configure_autoship_cancel')"
		:show-close-button="false"
		@confirm="$emit('confirm')"
		@cancel="$emit('cancel')">
		<p
			class="text-center px-2"
			v-html="translate('configure_autoship_description')" />
	</custom-modal>
</template>
<script>

import CustomModal from '@/components/CustomModal';
import { BecomeAffiliate } from '@/translations';

export default {
	name: 'AutoshipInfoModal',
	components: { CustomModal },
	messages: [BecomeAffiliate],
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
