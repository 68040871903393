<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row">
			<div class="col-12">
				<form
					@submit.prevent="prepareForStorage()"
					@keydown="clearValidationError($event.target.name);"
					@change="clearValidationError($event.target.name);">
					<li
						v-if="showSponsor"
						class="list-group-item border-left-0 border-right-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div class="mb-2">
									<span class="h4">{{ translate('sponsor_username') }} </span><span class="h4 text-danger">*</span>
									<span
										v-b-tooltip.hover.html.right
										:title="translate('sponsor_description', {company: companyName, supportLink: companyEmail, support: companyEmail })"
										class="text-muted small mx-2">
										<i class="fas fa-lg fa-info-circle" />
									</span>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="form-group">
									<input-text
										id="sponsor"
										:placeholder="translate('sponsor_username')"
										:show-label="false"
										:setter-value="sponsor.username"
										:errors="validationErrors['sponsor']"
										required
										type="text"
										autocomplete="sponsor"
										@dataChanged="sponsor.id = $event" />
								</div>
							</div>
						</div>
					</li>
					<li
						v-else
						class="list-group-item">
						<div class="row">
							<div class="col-12">
								<div class="">
									<b-alert
										show
										class="m-0">
										<span class="h5">{{ translate('selected_sponsor') }}: </span>
										<span class="h5 font-weight-bold">{{ sponsor.name }} </span>
										<!-- <span class="h5 font-weight-bold">({{ translate('id_word') }}: {{ sponsor.id }})</span> -->
									</b-alert>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item border-0 rounded-0 py-0">
						<div class="row">
							<div class="col-12">
								<div
									class="h4 pb-0 mb-0">
									{{ translate('personal_information') }}
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item border-top-0 border-left-0 border-right-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.first_name`"
										:label="translate('first_name')"
										:setter-value="form.first_name"
										:errors="validationErrors[`${currentValidation}.first_name`]"
										required
										type="text"
										autocomplete="given-name"
										@dataChanged="form.first_name = $event" />
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.last_name`"
										:label="translate('last_name')"
										:setter-value="form.last_name"
										:errors="validationErrors[`${currentValidation}.last_name`]"
										required
										type="text"
										autocomplete="family-name"
										@dataChanged="form.last_name = $event" />
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.email`"
										:label="translate('email')"
										:setter-value="form.email"
										:errors="validationErrors[`${currentValidation}.email`]"
										required
										:max="maxEmailLength"
										type="text"
										autocomplete="email"
										@dataChanged="form.email = $event" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label
										for="country_code"
										class="label">{{ translate('country') }} <span class="text-danger">*</span></label>
									<select
										id="country_code"
										v-model="form.country_code"
										name="country_code"
										:class="validationErrors[`${currentValidation}.country_code`] ? 'is-invalid' : ''"
										autocomplete="country-name"
										class="form-control">
										<template>
											<option value="">
												{{ translate('select_country') }}
											</option>
											<option
												v-for="country in countries"
												:key="country.id"
												:value="country.attributes.code">
												{{ translate(country.attributes.code.toLowerCase()) }}
											</option>
										</template>
									</select>
									<span
										v-for="error in validationErrors[`${currentValidation}.country_code`]"
										:key="error"
										class="custom-invalid-feedback animated fadeIn"
										v-text="error" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="mobile_number">
										{{ translate('mobile_number') }}
										<span class="text-danger">*</span>
									</label>
									<vue-tel-input
										:id="`${currentValidation}.mobile_number`"
										ref="mobileNumberInput"
										v-model="form.mobile_number"
										required
										:label="translate('mobile_number')"
										:placeholder="translate('mobile_number')"
										:class="validationErrors[`${currentValidation}.mobile_number`] ? 'is-invalid' : ''"
										v-bind="bindProps"
										type="text"
										:name="`${currentValidation}.mobile_number`"
										class="form-control rounded-1"
										autocomplete="tel"
										@dataChanged="form.mobile_number = $event" />
									<template v-if="validationErrors[`${currentValidation}.mobile_number`]">
										<span
											v-for="error in validationErrors[`${currentValidation}.mobile_number`]"
											:key="error"
											class="invalid-feedback animated fadeIn"
											v-text="error" />
									</template>
								</div>
							</div>
						</div>
					</li>
					<li
						class="list-group-item border-0 rounded-0 py-0">
						<div class="row">
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.username`"
										:label="translate('username')"
										:setter-value="form.username"
										:errors="validationErrors[`${currentValidation}.username`]"
										required
										type="text"
										autocomplete="off"
										@dataChanged="form.username = $event">
										<template slot="afterLabelInline">
											<span
												v-b-tooltip.hover.html.right
												:title="translate('username_used_for_replicated_site', { website: `${website}/${form.username || translate('username')}` })"
												class="text-muted small mx-2">
												<i class="fas fa-lg fa-info-circle" />
											</span>
										</template>
									</input-text>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.password`"
										:label="translate('password')"
										:setter-value="form.password"
										:errors="validationErrors[`${currentValidation}.password`]"
										required
										type="password"
										autocomplete="off"
										@dataChanged="form.password = $event" />
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.password_confirmation`"
										:label="translate('password_confirmation')"
										:setter-value="form.password_confirmation"
										:errors="validationErrors[`${currentValidation}.password_confirmation`]"
										required
										type="password"
										class="text-nowrap"
										autocomplete="off"
										@dataChanged="form.password_confirmation = $event" />
								</div>
							</div>
						</div>
					</li>
				</form>
				<li class="list-group-item border-0 rounded-0 pt-0 pb-3">
					<div class="row no-gutters justify-content-end">
						<div class="col">
							<disclaimer
								:checked="disclaimer"
								:terms="null"
								@change="disclaimer = $event" />
						</div>
						<div
							:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
							class="col-12 col-md-3">
							<b-button
								:disabled="validating || openPhoneVerificationModal || disableButton"
								variant="primary"
								size="lg"
								style="min-width: 200px;"
								class="w-100"
								@click="prepareForStorage()">
								<i
									v-if="validating"
									class="fas fa-spinner fa-spin mr-2" />{{ translate('create_affiliate_account') }}
							</b-button>
						</div>
					</div>
				</li>
			</div>
		</div>
		<phone-verification-modal
			v-show="isPhoneVerificationRequired"
			:phone-number="form.mobile_number"
			:open.sync="openPhoneVerificationModal"
			recaptcha-action-name="checkout_verification"
			is-register
			@verified="handlePhoneVerified"
			@changeNumber="focusMobileNumberInput"
			@phoneValidationError="focusMobileNumberInput" />
	</div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import InputText from '@/components/InputText';
import { MAX_EMAIL_LENGTH } from '@/settings/Validations';
import { FORBIDDEN, NOT_FOUND, UNPROCESSABLE } from '@/settings/Errors';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from '../../mixins/Common';
import Country from '@/util/Country';
import PhoneVerification from '@/util/PhoneVerification';
import Disclaimer from '@/views/Checkout/components/Confirmation/Disclaimer';
import { PHONE_VERIFICATION_STATES, VERIFY_PHONE_COUNTRIES } from '@/settings/PhoneVerification';
import PhoneVerificationModal from '@/components/PhoneVerificationModal';
import { VALID_COUNTRIES as availableCountries } from '@/settings/Country';

export default {
	name: 'PersonalInformationEdit',
	components: {
		InputText,
		VueTelInput,
		Disclaimer,
		PhoneVerificationModal,
	},
	mixins: [CommonMix, WindowSizes],
	data() {
		return {
			countryData: new Country(),
			phoneVerification: new PhoneVerification(),
			sponsor: {
				id: '',
				name: '',
			},
			showSponsor: true,
			maxEmailLength: MAX_EMAIL_LENGTH,
			website: process.env.VUE_APP_WEBSITE,
			bindProps: {
				mode: 'international',
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			form: {
				first_name: '',
				last_name: '',
				email: '',
				country_code: '',
				mobile_number: '',
				username: '',
				password: '',
				password_confirmation: '',
			},
			verificationPhoneToken: '',
			disclaimer: false,
			companyName: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			openPhoneVerificationModal: false,
			currentVerificationState: PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED,
			availableCountries,
		};
	},
	computed: {
		countries() {
			try {
				return this.countryData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		disableButton() {
			return !this.disclaimer || Object.entries(this.form).some(([, value]) => value === '');
		},
		isOrganizationVerified() {
			return this.phoneVerification.data.response.data.response.is_verified;
		},
		isPhoneVerificationRequired() {
			return this.currentVerificationState === PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED
				&& VERIFY_PHONE_COUNTRIES.includes(this.form.country_code);
		},
	},
	watch: {
		// eslint-disable-next-line func-names
		'form.country_code': function () {
			if (this.form.country_code.length > 0) {
				this.setStoredCountry(this.form.country_code);
			}
			this.changePhoneCountry(this.form.country_code);
		},
	},
	created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = this.availableCountries;
		const sponsorId = this.$replicated.replicatedSponsor();
		if (sponsorId !== null) {
			this.sponsor.id = String(sponsorId);
			this.showSponsor = false;

			this.getSponsorInfo(sponsorId).then((response) => {
				Object.keys(this.sponsor).forEach((value) => {
					this.sponsor[value] = response[value];
				});
			});
		}
		this.countryData.getCountries().then(() => {
			this.form.country_code = this.country ?? '';
			this.changePhoneCountry(this.form.country_code);
		});
	},
	methods: {
		initializePhoneVerification() {
			this.verificationPhoneToken = '';

			if (VERIFY_PHONE_COUNTRIES.includes(this.form.country_code)) {
				this.currentVerificationState = PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED;
			} else {
				this.currentVerificationState = PHONE_VERIFICATION_STATES.VERIFICATION_NOT_REQUIRED;
			}
		},
		handlePhoneVerified(token) {
			this.verificationPhoneToken = token;
			this.prepareForStorage();
		},
		focusMobileNumberInput() {
			this.$nextTick(() => {
				this.$refs.mobileNumberInput.focus();
			});
		},
		openPhoneVerification() {
			this.openPhoneVerificationModal = true;
		},
		prepareForStorage() {
			this.validateForm()
				.then(() => {
					if (this.verificationPhoneToken === '' && this.isPhoneVerificationRequired && !this.isOrganizationVerified) {
						this.openPhoneVerification();
					} else {
						this.$emit('saved', this.form);
					}
				})
				.catch((error) => this.handleValidateFormErrors(error));
		},
		async validateForm() {
			await this.phoneVerification.isOrganizationVerified(this.sponsor.id);

			return new Promise((resolve, reject) => {
				const form = { ...this.form };
				const payload = {
					step: this.currentValidation,
					sponsor: this.sponsor.id,
					personal_information: form,
				};
				if (this.verificationPhoneToken) {
					payload.verification_phone_token = this.verificationPhoneToken;
				}

				this.validateStep(payload)
					.then(() => resolve(payload))
					.catch((error) => reject(error));
			});
		},
		handleValidateFormErrors(error) {
			if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
				this.$emit('invalidRequest', error);
			}
			if (UNPROCESSABLE.includes(error.status)) {
				const { sponsor } = error.errors;
				// Redirect to the store if the sponsor was selected via the replicated site, and they get a validation error
				if (!this.showSponsor && typeof sponsor !== 'undefined') {
					this.$emit('cantUseSelectedSponsor');
				}
			}
		},
		changePhoneCountry(countryCode) {
			if (countryCode.length > 0) {
				this.form.mobile_number = `+${this.countries.find((country) => country.attributes.code === countryCode)?.attributes?.phonecode ?? ''}`;
			}
		},
	},
};
</script>
