import { BECOME_AFFILIATE_STEPS as flowSteps } from '@/settings/Wizard';
import {
	Addresses, Countries, Dates, Events, Grids, Months, NIN, Packages, PaymentMethods, Products, Profile, Purchase,
	Users, Validations, BecomeAffiliate,
} from '@/translations';
import Checkout from '@/util/Purchase';
import Captcha from '@/mixins/Captcha';

export default {
	messages: [Addresses, Countries, Dates, Events, Grids, Months, NIN, Packages, PaymentMethods, Products, Profile, Purchase, Users, Validations, BecomeAffiliate],
	mixins: [Captcha],
	data() {
		return {
			validator: new Checkout(),
		};
	},
	computed: {
		steps() {
			const steps = [];
			Object.keys(flowSteps).forEach((stepName) => {
				if (flowSteps[stepName].innerSteps) {
					steps.push(...flowSteps[stepName].innerSteps);
				}
				steps.push({ [stepName]: flowSteps[stepName] });
			});
			return steps;
		},
		stepNames() {
			return this.steps.map((step) => Object.keys(step)[0]);
		},
		validating() {
			try {
				return this.validator.data.loading;
			} catch (error) {
				return false;
			}
		},
		validationErrors() {
			try {
				return this.validator.errors.errors;
			} catch (error) {
				return [];
			}
		},
		currentModule() {
			const [mod] = this.$route.matched;
			return mod.name;
		},
		currentValidation() {
			return this.getStepValidationByIndex(0);
		},
	},
	methods: {
		getSponsorInfo(sponsorId) {
			return this.validator.getSponsorInfo(sponsorId)
				.then((response) => response.response)
				.catch((error) => Promise.reject(error));
		},
		validateStep(payload) {
			if (this.validating) {
				return null;
			}
			return this.getCaptchaToken('checkout').then((token) => {
				payload['g-recaptcha-response'] = token;
				return this.validator.validate(payload, this.currentModule)
					.then((response) => response)
					.catch((error) => Promise.reject(error));
			});
		},
		clearValidationError(field) {
			this.validator.errors.clear(field);
		},
		getStepByIndex(index) {
			return this.steps[index];
		},
		getStepNameByIndex(index) {
			return this.stepNames[index];
		},
		getStepValidationByIndex(index) {
			try {
				return this.getStepByIndex(index)[this.getStepNameByIndex(index)].validation;
			} catch (error) {
				return false;
			}
		},
	},
};
